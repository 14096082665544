<template>
  <div class="search">
    <van-search
      v-model="search_ward"
      show-action
      shape="round"
      :clearable='false'
      :placeholder="placeholder"
      :input-align="align"
    >
      <template #action>
        <div @click="onSearch" class="seachStyle">{{search}}</div>
      </template>
      <template #left-icon>
          <van-icon :name="icon" />
      </template>
    </van-search>
  </div>
</template>

<script>
export default {
    props:{
        placeholder:{
            type: String
        },
        align:{
            type:String,
            default:'left'
        },
        search : {
            type:String,
            default:'搜索'
        },
        icon:{
            type:String,
            default:'search'
        },
        // search_ward:{
        //     type:String
        // }
    },
    data () {
        return {
            search_ward:''
        }
    },
    watch:{
        'search_ward':{
            handler(val) {
                this.onSearch()
            }
        }
    },
    methods:{
        onSearch () {
            this.$emit('vaeSearch',this.search_ward)
        }
    }
}
</script>

<style lang="less" scoped>
    .search {
        ::v-deep .seachStyle {
            font-size: 13px;
            padding: 0 10px;
            border-radius: 18px;
            color: #fff !important;
            background-color: #2163e9 !important;
        }
        ::v-deep .van-search {
            background: none;
        }
        ::v-deep .van-search__content--round {
            background-color: #fff;
            box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
        }
    }
</style>
