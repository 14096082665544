<template>
  <div class="app">
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
    />
    <keep-alive include="homePage,medication,student">
    <router-view />
    </keep-alive>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // active: 0,
    }
  },
  created () {
    // document.body.addEventListener(
    //   'touchmove',
    //   function (e) {
    //     e.preventDefault()
    //   },
    //   { passive: false }
    // )
  },
}
</script>

<style lang="less">
.app {
  height: calc(100vh - 145px);
  .fz14 {
    font-size: 14px;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
// 去除滚动条
body,
#html {
  width: 100%;
  height: 100%;
  overflow: scroll;
  background: #f8f9f9;
}
html::-webkit-scrollbar, 
body::-webkit-scrollbar{width:0px;height:0px;}
body{margin:0;}

img {
  pointer-events: none;
}
.vxe-table--body-wrapper {
  // -webkit-overflow-scrolling: touch;
  -webkit-overflow-scrolling: auto !important; //阻止ios自带滑动具有惯性，阻尼回弹等特性
}
.one {
  // 超过一行省略号
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow:hidden;
  text-overflow:ellipsis;
}
.two {
  //超过两行省略号
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
}
.container{
  width:100vw;
  height:100vh;
}
#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    overflow-x: hidden;
}
.appView {
    position: absolute;
    width: 100%;
    background: #fff;
    min-height: 100vh;
    transition: transform 0.24s ease-out;
}
#app.quickback .appView{
    transition-duration: 0.1s;
}
.slide-left-enter {
    transform: translate(100%, 0);
}
.slide-left-leave-active {
    transform: translate(-50%, 0);
}
.slide-right-enter {
    transform: translate(-50%, 0);
}
.slide-right-leave-active {
    transform: translate(100%, 0);
}
</style>
