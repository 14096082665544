// 封装本地存储方法
// 存
function setLocal (key,value) {
    return window.localStorage.setItem(key,value)
}
// 取
function getLocal (key) {
    return window.localStorage.getItem(key)
}
// 删除
function removeLocal (key) {
    return window.localStorage.removeItem(key)
}
// 导出
export {setLocal,getLocal,removeLocal}