// npm install axios --save
import axios from 'axios'
import { getLocal, removeLocal } from './local'
import { Toast } from 'vant'

// 基地址
const _fetch = axios.create({
  // baseURL: 'https://eye-api.eyebright.net.cn', //线上
  baseURL: 'https://gx.api.eyebright.net.cn', // 测试
  // baseURL: 'http://eyes.com' // 测试
})

// 添加请求拦截器
_fetch.interceptors.request.use(
  config => {
    if (config.needToken) {
      config.headers['token'] = getLocal('token')
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

//响应拦截
_fetch.interceptors.response.use(
  function (response) {
    console.log("response",response)
    if (response.data.code == 413 || response.data.code == 412) {
      Toast.fail('请重新登录！')
      removeLocal('token')
      setTimeout(() => {
        this.$router.push('/login')
      }, 1000)
    }
    if (response.data.code == 406) {
      Toast.fail('不存在用户！')
    }
    return response
  },
  function (error) {
    return Promise.reject(error)
  }
)
export default _fetch
