import Vue from 'vue'
import VueRouter from 'vue-router'
import { getLocal, removeLocal } from '../utils/local'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/homePage'
  },
  {
    name: '登录',
    path: '/login',
    component: () => import('@/views/login/login.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/home',
    component: () => import('@/views/home.vue'),
    redirect: '/homePage',
    meta: {
      title: '商城首页',
      needTab: true
    },
    children: [
      {
        name: '我的',
        path: '/myInfo',
        component: () => import('@/views/myInfo/myInfo.vue'),
        meta: {
          title: '我的',
          needTab: true,
          needToken: true
        }
      },
      {
        name: '商城首页',
        path: '/homePage',
        component: () => import('@/views/homePage/homePage.vue'),
        meta: {
          title: '商城首页',
          needTab: true
        }
      },
      {
        name: '切换城市',
        path: '/switchCity',
        component: () => import('@/views/switchCity/switchCity.vue'),
        meta: {
          title: '切换城市'
        }
      },
      {
        name: '搜索',
        path: '/searchInfo',
        component: () => import('@/views/homePage/searchInfo.vue'),
        meta: {
          title: '搜索'
        }
      },
      {
        name: '学生护眼产品',
        path: '/student',
        component: () => import('@/views/student/index.vue'),
        meta: {
          title: '学生护眼产品'
        }
      },
      {
        name: '护眼产品',
        path: '/typeClassification',
        component: () => import('@/views/student/typeClassification.vue'),
        meta: {
          title: '护眼产品'
        }
      },
      {
        name: '学校护眼方案',
        path: '/school',
        component: () => import('@/views/school/index.vue'),
        meta: {
          title: '学校护眼方案'
        }
      },
      {
        name: '热门医院',
        path: '/hospital',
        component: () => import('@/views/homePage/hospital.vue'),
        meta: {
          title: '热门医院'
        }
      },
      {
        name: '医院详情页',
        path: '/hospitalInfo',
        component: () => import('@/views/homePage/hospitalInfo.vue'),
        meta: {
          title: '医院详情页'
        }
      },
      {
        name: '健康资讯',
        path: '/healthAdvice',
        component: () => import('@/views/homePage/healthAdvice.vue'),
        meta: {
          title: '健康资讯'
        }
      },
      {
        name: '文章详情页',
        path: '/healthDetail',
        component: () => import('@/views/homePage/healthDetail.vue'),
        meta: {
          title: '文章详情页'
        }
      },
      {
        name: '搜索',
        path: '/search',
        component: () => import('@/views/homePage/search.vue'),
        meta: {
          title: '搜索'
        }
      },
      {
        name: '护眼训练',
        path: '/eyeTraning',
        component: () => import('@/views/eyeService/eyeTraning.vue'),
        meta: {
          title: '护眼训练',
          needToken: true
        }
      },
      {
        name: '在线问诊',
        path: '/eyePhysician',
        component: () => import('@/views/eyeService/eyePhysician.vue'),
        meta: {
          title: '在线问诊',
          needToken: true
        }
      },
      {
        name: '门诊用药',
        path: '/medication',
        component: () => import('@/views/eyeService/medication.vue'),
        meta: {
          title: '门诊用药'
        }
      },
      {
        name: '门诊用药',
        path: '/medicationDetail',
        component: () => import('@/views/eyeService/medicationDetail.vue'),
        meta: {
          title: '门诊用药'
        }
      },
      {
        name: '我的客服',
        path: '/customer',
        component: () => import('@/views/eyeService/customer.vue'),
        meta: {
          title: '我的客服'
        }
      },
      {
        name: '商品详情',
        path: '/goodsDetail',
        component: () => import('@/views/student/goodsDetail.vue'),
        meta: {
          title: '商品详情'
        }
      },
      {
        name: '商品详情',
        path: '/drugDetail',
        component: () => import('@/views/eyeService/drugDetail.vue'),
        meta: {
          title: '商品详情'
        }
      },
      {
        name: '商品详情',
        path: '/outpatient',
        component: () => import('@/views/eyeService/outpatient.vue'),
        meta: {
          title: '商品详情'
        }
      },
      {
        name: '我的消息',
        path: '/myNews',
        component: () => import('@/views/myInfo/myNews.vue'),
        meta: {
          title: '我的消息',
          needToken: true
        }
      }
    ]
  },
  {
    name: '注册',
    path: '/registered',
    component: () => import('@/views/login/registered.vue'),
    meta: {
      title: '注册'
    }
  }
]

// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  // 修改页面标题
  document.title = to.meta.title
  const _scrollTop =
    document.documentElement.scrollTop ||
    document.body.scrollTop ||
    window.pageYOffset
  from.meta.scrollTop = _scrollTop
  if (!to.meta.needToken) {
    next()
  } else {
    if (getLocal('token')) {
      next()
    } else {
      next('/login?redirect=' + to.fullPath)
    }
  }
})
router.afterEach(() => {
  // 每跳转新页面 回到顶部
  window.scrollTo(0, 0)
})
export default router
