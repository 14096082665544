import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Vant from 'vant'
import 'vant/lib/index.css'
import request from './utils/request'
import {VueJsonp} from 'vue-jsonp'
Vue.use(VueJsonp)
// import VideoPlayer from 'vue-video-player'
// require('video.js/dist/video-js.css')
// require('vue-video-player/src/custom-theme.css')
// Vue.use(VideoPlayer)

import Videojs from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$video = Videojs
// import wx from 'weixin-js-sdk'
var wx = require('weixin-js-sdk'); // 已安装
import touch from 'vue-directive-touch';
// import Vconsole from 'vconsole'
// const vConsole = new Vconsole()
// Vue.use(vConsole)
Vue.use(touch);
// 全局注册
import vaeSearch from '@/components/search.vue'
Vue.component('vaeSearch',vaeSearch)
import {Icon} from 'vant'
import 'amfe-flexible'
Vue.use(Icon)
// 使用 vant
Vue.use(Vant)
Vue.config.productionTip = false
Vue.prototype.$request = request

new Vue({
  el: '#app',
  router,
  store,
  render: createElement => createElement(App)
})